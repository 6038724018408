const API_URL = process.env.NODE_ENV === "production" ? "https://api.naxalian.fr/v2" : "http://localhost:8080";


// AUTHENTICATION

export const userinfo = () => new Promise((resolve, reject) => {

    if (!localStorage.getItem("token")) return reject("No token found");

    fetch(API_URL + "/accounts/@me", {
        method: "GET",
        headers: { "Authorization": localStorage.getItem("token") }
    }).then((res) => {
        if (res.status === 200) res.json().then((res) => resolve(res.data)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => {
        if (localStorage.getItem("token")) localStorage.removeItem("token");
        reject(error)
    });

});


// ALL


export const newOrder = (name, budget, requester_name, requester_phone, requester_mail, content) => {
    return new Promise((resolve, reject) => {

        fetch(API_URL + "/apps/studios/orders", {
            method: "POST",
            headers: { "Authorization": localStorage.getItem("token"), "Content-Type": "application/json" },
            body: JSON.stringify({ name: name, budget: budget, requester_name: requester_name, requester_phone: requester_phone, requester_mail: requester_mail, content: content })
        }).then((res) => {
            if (res.status === 200) res.json().then((res) => resolve(res.message)).catch((error) => reject(error));
            else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
        }).catch((error) => reject(error));
    });

}

export const getOrders = () => {

    return new Promise((resolve, reject) => {

        fetch(API_URL + "/apps/studios/orders", {
            method: "GET",
            headers: { "Authorization": localStorage.getItem("token") }
        }).then((res) => {
            if (res.status === 200) res.json().then((res) => resolve(res.orders)).catch((error) => reject(error));
            else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
        }).catch((error) => reject(error));

    });

}

export const getOrderinfo = (orderId) => {

    return new Promise((resolve, reject) => {

        fetch(API_URL + "/apps/studios/orders/" + orderId, {
            method: "GET",
            headers: { "Authorization": localStorage.getItem("token") }
        }).then((res) => {
            if (res.status === 200) res.json().then((res) => resolve(res.data)).catch((error) => reject(error));
            else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
        }).catch((error) => reject(error));

    });

}

export const cancelOrder = (orderId) => {

    return new Promise((resolve, reject) => {

        fetch(API_URL + "/apps/studios/orders/" + orderId, {
            method: "DELETE",
            headers: { "Authorization": localStorage.getItem("token") }
        }).then((res) => {
            if (res.status === 200) res.json().then((res) => resolve(res.message)).catch((error) => reject(error));
            else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
        }).catch((error) => reject(error));

    });

}

export const confirmEstimate = (orderId) => {

    return new Promise((resolve, reject) => {

        fetch(API_URL + "/apps/studios/orders/" + orderId + "/estimate", {
            method: "POST",
            headers: { "Authorization": localStorage.getItem("token") }
        }).then((res) => {
            if (res.status === 200) res.json().then((res) => resolve(res.message)).catch((error) => reject(error));
            else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
        }).catch((error) => reject(error));

    });

}


// FILES

export const downloadFile = (orderId, filePathUrl) => new Promise((resolve, reject) => {

    fetch(API_URL + "/apps/studios/orders/" + orderId + "/files/" + filePathUrl, {
        method: "GET",
        headers: { "Authorization": localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.blob().then((blob) => resolve({ blob, contentType: res.headers.get('content-type') }));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));

});