import React from 'react';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../components/styles/jobs.scss';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

class Jobs extends React.Component {

    constructor(props) {
        super(props)

        this.startRef = React.createRef()
        this.contactRef = React.createRef()

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    }

    handleLoad = () => { this.setState({ loading: true }) }

    render() {
        return (
            <div className="Jobs">

                {this.state.loading ? <Loader /> : null}

                <Navigation darkNav={false} />
                <section className="hero">

                    <div className="container">

                        <h1 className="hero-title">Travaillons <i class="fi fi-sr-stars text-gold"></i><br />ensemble.</h1>
                        <span className="hero-subtitle">Intégrez notre équipe pour participer à la création de projets innovants et variés.<br />
                            Vous êtes attiré par le monde du web et du numérique ? Vous êtes au bon endroit.</span>

                    </div>


                </section>

                <section className="offers">

                    <div className="container">

                        <div className="row d-flex">
                            <div className="col-12 col-md-4 mx-auto d-equal mb-3">

                                <div className="project d-flex flex-column h-100">
                                    <div className="identity">
                                        <img src={process.env.PUBLIC_URL + '/assets/logo_naxalian.png'} alt="Naxalian Studio" />
                                        <span className="description">
                                            <h2>Naxalian Studios</h2>
                                            <p>Agence de développement d'environnements web.</p>
                                        </span>
                                    </div>

                                    <div className="offers-list flex-grow-1">
                                        <div className="offer">
                                            <div className="details">
                                                <span className="title">Prospecteur 🕵️‍♂️</span>
                                                <span className="status"><i class="fi fi-sr-briefcase me-2"></i> Freelance</span>
                                            </div>
                                            <Link to="https://naxalian.fr/discord" className="btn-send">Postuler</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-4 mx-auto d-equal mb-3">

                                <div className="project d-flex flex-column h-100">
                                    <div className="identity">
                                        <img src={process.env.PUBLIC_URL + '/assets/logo_naxalian.png'} alt="Project Logo" />
                                        <span className="description">
                                            <h2>Workplace</h2>
                                            <p>Filiale SaaS de gestion de projets collaboratifs.</p>
                                        </span>
                                    </div>

                                    <div className="offers-list flex-grow-1">
                                        <div className="offer">
                                            <div className="details">
                                                <span className="title">Développeur Full-Stack 🧑‍🔬</span>
                                                <span className="status"><i class="fi fi-sr-briefcase me-2"></i> Bénévole</span>
                                            </div>
                                            <Link to="https://naxalian.fr/discord" className="btn-send">Postuler</Link>
                                        </div>
                                        <div className="offer">
                                            <div className="details">
                                                <span className="title">Prospecteur 🕵️‍♂️</span>
                                                <span className="status"><i class="fi fi-sr-briefcase me-2"></i> Freelance</span>
                                            </div>
                                            <Link to="https://naxalian.fr/discord" className="btn-send">Postuler</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-4 mx-auto d-equal mb-3">

                                <div className="project d-flex flex-column h-100">
                                    <div className="identity">
                                        <img src={process.env.PUBLIC_URL + '/assets/promease.png'} alt="Project Logo" />
                                        <span className="description">
                                            <h2>PromEase</h2>
                                            <p>Filiale SaaS d'organisation de bals de promo.</p>
                                        </span>
                                    </div>

                                    <div className="offers-list flex-grow-1">
                                        <div className="offer">
                                            <div className="details">
                                                <span className="title">Community Manager 📱</span>
                                                <span className="status"><i class="fi fi-sr-briefcase me-2"></i> Freelance</span>
                                            </div>
                                            <Link to="https://naxalian.fr/discord" className="btn-send">Postuler</Link>
                                        </div>
                                        <div className="offer">
                                            <div className="details">
                                                <span className="title">Ambassadeur 🤝</span>
                                                <span className="status"><i class="fi fi-sr-briefcase me-2"></i> Freelance</span>
                                            </div>
                                            <Link to="https://naxalian.fr/discord" className="btn-send">Postuler</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </section>



                <Footer />

            </div>
        );
    }
}

export default Jobs;