import React from 'react';

import { NavLink } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <span className="footer-text">2024 © Naxalian Studios</span>

                <div className="footer-links">
                    <NavLink className="footer-link" to="/legal">Mentions légales</NavLink>
                    <button className="btn btn-link footer-link" onClick={() => window.scrollTo(0, 0)}>Haut de page</button>
                </div>
            </footer>
        );
    }
}

export default Footer;