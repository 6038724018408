import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';


class Loader extends React.Component {
    render() {
        return (

            <Backdrop
                sx={{ color: '#232323', zIndex: (theme) => theme.zIndex.drawer + 9999, backgroundImage: "linear-gradient(90deg, #63636307 1px, transparent 1px), linear-gradient(180deg, #63636307 1px, transparent 1px);", backgroundSize: "8px 8px", backgroundColor: "#f5f5f5" }}
                open={true}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress
                        color="primary"
                        fourColor
                        variant="indeterminate"
                    />
                </div>
            </Backdrop>
        );
    }
}

export default Loader;