import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './components/styles/global.scss';

import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import Legal from "./pages/Legal";
import Orders from "./pages/clientarea/Orders";
import Tracking from './pages/clientarea/Tracking';
import InvoiceExport from './pages/utils/Invoice';

function App() {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/jobs"} element={<Jobs />} />
        <Route path={"/legal"} element={<Legal />} />
        <Route path={"/clientarea"} element={<Orders />} />
        <Route path={"/clientarea/:orderId"} element={<Tracking />} />
        <Route path={"/clientarea/:orderId/invoice"} element={<InvoiceExport />} />
      </Routes>
    </Router>
  );
}

export default App;
