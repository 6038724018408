import React from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getOrderinfo, userinfo } from '../../components/api';
import toast from 'react-hot-toast';
import '../../components/styles/invoice.scss';


class Invoice extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            invoiceinfo: [],
        };

    }

    componentDidMount() {

        userinfo().then((res) => {
            if (!res && !res.nametag) return toast.error("Une erreur est survenue lors de la récupération de vos informations.");
        }).catch((error) => {
            toast.error("Vous devez être connecté pour accéder à cette page.");
            let base64 = btoa("https://studios.naxalian.fr/?token=")
            window.location.href = "https://naxalian.fr/login?redirect_uri=" + base64;
        });

        getOrderinfo(this.props.params.orderId).then((res) => {
            this.setState({ invoiceinfo: res.invoice, loading: false });

            if (res.orderinfo.status === 0 || res.orderinfo.status === 6) return window.location.href = "./";

        }).catch((error) => {
            toast.error("Une erreur est survenue lors de la récupération des informations de la commande.");
        })

    }



    render() {
        return (
            <div className="Invoice">
                <div className="container">
                    <div className="card__invoice mb-5">
                        <div className="invoice__header">
                            <div className="description">
                                <h1 className="invoice__title">Facture pour la commande #{this.props.params.orderId}</h1>
                                {
                                    this.state.invoiceinfo.status === 1 ? <span className="invoice__status status__payed__partially">PAYÉE PARTIELLEMENT</span> : this.state.invoiceinfo.status === 2 ? <span className="invoice__status status__payed">PAYÉE</span> : <span className="invoice__status status__unpayed">NON PAYÉE</span>
                                }
                            </div>
                            <h2 className="invoice__subtitle">Émise par Naxalian Studios le <b>{this.state.invoiceinfo.date_emitted ? new Date(this.state.invoiceinfo.date_emitted * 1000).toLocaleDateString() : "N/A"}</b></h2>
                        </div>


                        <div className="row border-price">

                            <div className="col-xs-12 col-md-6 text-center mb-xs-5 mb-md-0">

                                <h3 className="invoice__price">Prix total</h3>
                                <span className="invoice__price__amount">{this.state.invoiceinfo.price || this.state.invoiceinfo.price === 0 ? this.state.invoiceinfo.price + "€" : "N/A"}</span><br />
                                <small className="text-muted">Conforme au devis</small>

                            </div>
                            <div className="col-xs-12 col-md-6 text-center">

                                <h3 className="invoice__price">Reste à payer</h3>
                                <span className="invoice__price__amount">{this.state.invoiceinfo.price || this.state.invoiceinfo.price === 0 ? (this.state.invoiceinfo.price - this.state.invoiceinfo.money_sent) + "€" : "N/A"}</span><br />
                                <small className="text-muted">soit {this.state.invoiceinfo.price || this.state.invoiceinfo.price === 0 ? (this.state.invoiceinfo.money_sent) : "N/A"}€ payé{this.state.invoiceinfo.price && this.state.invoiceinfo.money_sent > 1 ? "s" : ""}</small>

                            </div>


                        </div>


                        <div className="instructions">
                            <i class="fi fi-rr-info ms-2" /> Vous pouvez régler cette facture par virement PayPal. Pour rappel, votre commande ne sera commencée qu'une fois 50% du montant total payé.
                        </div>

                        <div className="invoice__details">
                            <Markdown options={{ remarkPlugins: [remarkGfm] }} children={this.state.invoiceinfo ? this.state.invoiceinfo.detail_md : `# Invoice for Order ${this.props.params.orderId}`} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const InvoiceExport = (props) => <Invoice {...props} params={useParams()} />;
export default InvoiceExport;
