import React from 'react';
import '../../components/styles/dashboard.scss';
import Navigation from '../../components/Navigation';
import Loader from '../../components/Loader';
import { NavLink } from 'react-router-dom';
import { getOrders, userinfo } from '../../components/api';
import toast from 'react-hot-toast';

class Orders extends React.Component {

    constructor(props) {
        super(props)

        this.creationRef = React.createRef()
        this.startRef = React.createRef()
        this.contactRef = React.createRef()

        this.state = {
            loading: true,
            nametag: null,
            orders: []
        }
    }

    componentDidMount() {

        userinfo().then((res) => {
            if (!res && !res.nametag) return toast.error("Une erreur est survenue lors de la récupération de vos informations.");
            this.setState({ nametag: res.displayname ? res.displayname : "@" + res.nametag });

            // Si il est entre 0h et 18h on affiche bonjour sinon bonsoir
            if (this.state.nametag !== null) return toast.success("Bonjour " + this.state.nametag + " !", { icon: "👋" });


        }).catch((error) => {
            toast.error("Vous devez être connecté pour accéder à cette page.");
            let base64 = btoa("https://studios.naxalian.fr/?token=")
            window.location.href = "https://naxalian.fr/login?redirect_uri=" + base64;
        });

        getOrders().then((res) => {

            this.setState({ orders: res, loading: false });

        }).catch((error) => {
            toast.error("Une erreur est survenue lors de la récupération de vos commandes.");
        });


    }

    handleLoad = () => { this.setState({ loading: true }) }

    /*
    0: En attente d'instruction
    1: En cours de réalisation
    2: Terminée
    3: Annulée
    4: Rejetée
    5: Acceptée, action requise
    6: En cours d'instruction
    7: En attente de paiement
    
    */

    render() {
        return (
            <div className="Dashboard">

                <Navigation darkNav={false} />
                {this.state.loading ? <Loader /> : null}

                <section className="hero">

                    <div className="container">

                        <h1 className="hero-title">Vos commandes</h1>
                        <span className="hero-subtitle">Vous trouverez ici toutes les informations relatives à vos commandes et/ou à nos collaborations en cours.<br />
                            Cet espace est dédié au suivi et au bon déroulement de vos projets.</span>

                    </div>


                </section>

                <section className="project-list mb-5 pb-5">

                    <div className="container">
                        {this.state.orders && this.state.orders.length > 0 ?

                            this.state.orders.map((order, index) => {
                                return (
                                    <>
                                        <div className="project d-flex d-md-none" key={order.id} onClick={() => window.location.href = "./clientarea/" + order.id}>

                                                <div className="presentation">
                                                    <div className="identity">
                                                        <span className="title">{order.name}</span>
                                                        <small className="text-muted">
                                                            #{order.id}
                                                        </small>
                                                    </div>
                                                </div>

                                                <div className="tracking">
                                                    <span className={"status status__" +
                                                        (order.status === 0 ? "pending" : (order.status === 1 ? "in-progress" : (order.status === 7 ? "action__required" : (order.status === 6 ? "none" : (order.status === 2 ? "finished" : (order.status === 3 ? "canceled" : (order.status === 4 ? "rejected" : (order.status === 5 ? "action__required" : "rejected"))))))))}>
                                                        {order.status === 0 ? "En attente d'instruction" : (order.status === 7 ? "En attente de paiement" : (order.status === 6 ? "En cours d'instruction" : (order.status === 1 ? "En cours de réalisation" : (order.status === 2 ? "Commande terminée" : (order.status === 3 ? "Commande annulée" : (order.status === 4 ? "Commande rejetée" : (order.status === 5 ? "Acceptée, action requise" : "Commande perdue")))))))}
                                                    </span>
                                                </div>
                                        </div >
                                        <div className="project d-none d-md-flex" key={index}>

                                            <div className="presentation">
                                                <div className="identity">
                                                    <span className="title">{order.name}</span>
                                                    <small className="text-muted">
                                                        #{order.id}
                                                    </small>
                                                </div>
                                            </div>

                                            <div className="tracking">
                                                <small className="text-muted">Fait le {new Date(order.create_date * 1000).toLocaleDateString()}</small>
                                                <span className={"status status__" +
                                                    (order.status === 0 ? "pending" : (order.status === 1 ? "in-progress" : (order.status === 7 ? "action__required" : (order.status === 6 ? "none" : (order.status === 2 ? "finished" : (order.status === 3 ? "canceled" : (order.status === 4 ? "rejected" : (order.status === 5 ? "action__required" : "rejected"))))))))}>
                                                    {order.status === 0 ? "En attente d'instruction" : (order.status === 7 ? "En attente de paiement" : (order.status === 6 ? "En cours d'instruction" : (order.status === 1 ? "En cours de réalisation" : (order.status === 2 ? "Commande terminée" : (order.status === 3 ? "Commande annulée" : (order.status === 4 ? "Commande rejetée" : (order.status === 5 ? "Acceptée, action requise" : "Commande perdue")))))))}
                                                </span>
                                            </div>

                                            <div className="actions">
                                                {order.status === 0 || order.status === 6 ?
                                                    <button className="btn btn-link" disabled>Suivre <i className="fi fi-sr-angle-circle-right ms-2" /></button>
                                                    :
                                                    < NavLink className="btn btn-link" to={"/clientarea/" + order.id}>Suivre <i className="fi fi-sr-angle-circle-right ms-2" /></NavLink>}
                                            </div>

                                        </div>
                                    </>
                                );
                            })

                            : <span className="text-muted text-center">Vous n'avez aucune commande en cours.<br/><br/>
                                <NavLink to="/?command=true" className="btn btn-dark">Passer une commande</NavLink>
                            </span>}

                    </div>

                </section >

            </div >
        );
    }
}

export default Orders;