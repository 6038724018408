import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../components/styles/home.scss';
import '../components/styles/work.scss';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import { newOrder, userinfo } from '../components/api';
import { toast } from 'react-hot-toast';

class Home extends React.Component {

    constructor(props) {
        super(props)

        this.creationRef = React.createRef()
        this.startRef = React.createRef()
        this.contactRef = React.createRef()

        // Ref des champs du formulaire

        this.nameRef = React.createRef()
        this.budgetRef = React.createRef()
        this.requesterNameRef = React.createRef()
        this.requesterPhoneRef = React.createRef()
        this.requesterMailRef = React.createRef()
        this.descriptionRef = React.createRef()
        this.orderDetailsRef = React.createRef()

        this.state = {
            loading: false,
            nametag: null,
            mail: null
        }
    }

    componentDidMount() {

        const TOKEN = new URLSearchParams(this.props.location.search).get("token");
        const COMMAND = new URLSearchParams(this.props.location.search).get("command");
        if (TOKEN) localStorage.setItem("token", TOKEN);
        if (localStorage.getItem("token")) userinfo().then((res) => {
            this.setState({ nametag: res.displayname ? res.displayname : "@" + res.nametag.toLowerCase(), mail: res.mail });
            if (TOKEN) {
                this.contactRef.current.style.display = 'flex';
                this.contactRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            if (!TOKEN && COMMAND) { this.contactRef.current.style.display = 'flex'; this.contactRef.current.scrollIntoView({ behavior: 'smooth' }); }
        }).catch((error) => { localStorage.removeItem("token"); toast.error(error); });



    }

    handleLoad = () => { this.setState({ loading: true }) }

    handleOrder = () => {

        if (!this.descriptionRef.current.value || !this.orderDetailsRef.current.value) return toast.error("Veuillez remplir tous les champs obligatoires");

        let content = "**Description du projet :** " + String(this.descriptionRef.current.value) + "\n\n**Détails de la commande :** " + String(this.orderDetailsRef.current.value) + "\n\n";

        newOrder(this.nameRef.current.value, this.budgetRef.current.value, this.requesterNameRef.current.value, this.requesterPhoneRef.current.value, this.requesterMailRef.current.value, content).then((response) => {
            toast.success(response);

            // Vider tous les champs
            this.nameRef.current.value = "";
            this.budgetRef.current.value = "";
            this.requesterNameRef.current.value = "";
            this.requesterPhoneRef.current.value = "";
            this.requesterMailRef.current.value = "";
            this.descriptionRef.current.value = "";
            this.orderDetailsRef.current.value = "";
            // Fermer le formulaire
            setTimeout(() => {
                window.scrollTo(0, 0);
                this.contactRef.current.style.display = 'none';
            }, 1500);


            this.setState({ loading: false });
        }).catch((error) => {
            toast.error(error ? error : "Une erreur est survenue lors de l'envoi de la commande");
            this.setState({ loading: false });
        });

    }

    render() {
        return (
            <div className="Home Work">

                <Navigation darkNav={false} />
                {this.state.loading ? <Loader /> : null}

                <section className="hero">

                    <div className="container">

                        <h1 className="hero-title">Le <i>web digital</i> au <i class="fi fi-sr-heart heart" onClick={(e) => {
                            e.target.style.animation = "pulse 1s";
                            setTimeout(() => {
                                e.target.style.animation = "none";
                            }, 1000);
                        }}></i><br />de vos projets.</h1>
                        <span className="hero-subtitle">Avec Naxalian Studios, votre projet bénéficie de notre expertise axée sur le développement d'applications web.<br />Créons un environnement digital unique, pour offrir à vos équipes et clients la maniabilité qu'ils méritent. Façonnons une réussite digitale à la hauteur de vos ambitions.</span>

                        <div className="cta-section">

                            <button className="cta-button text-decoration-none" onClick={() => {

                                if (this.state.loading) return;
                                if (!this.state.nametag) {
                                    let base64 = btoa("https://studios.naxalian.fr/?token=")
                                    window.location.href = "https://naxalian.fr/login?redirect_uri=" + base64;
                                    return;
                                }

                                this.handleLoad();
                                setTimeout(() => {
                                    this.contactRef.current.style.display = 'flex';
                                    this.contactRef.current.scrollIntoView({ behavior: 'smooth' })
                                    this.setState({ loading: false });
                                }, 500);
                            }}>Prendre contact <i class="fi fi-rr-attribution-pencil ms-2" /></button>
                            <Link className="cta-button-outline text-decoration-none" to="https://naxalian.fr/discord">Rejoindre notre discord <i class="fi fi-brands-discord ms-3"></i></Link>
                        </div>

                    </div>


                </section>
                <section className="services">

                    <div className="container">
                        <div className="row">

                            <div className="col-xs-12 col-lg-4 mb-3">

                                <div className="card card__ratings h-100">
                                    <div className="card-body">
                                        <div className="card-top">
                                            <h6 className="card-title"><i class="fi fi-rr-star"></i> Avis</h6>
                                            <h5 className="card-subtitle mb-2 text-muted">4.9</h5>

                                        </div>

                                        <span className="content">Parce que les avis, ça compte.</span>

                                        <hr className="card-divider" />

                                        <div className="opinions">

                                            <div className="avatars-stack">
                                                <img src={"https://i.pravatar.cc/512"} alt="Avatar icon" className="author-avatar" />
                                                <img src={"https://i.pravatar.cc/1024"} alt="Avatar icon" className="author-avatar" />
                                            </div>
                                            <span className="content">Un design unique qui<br />a fait ses preuves.</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-xs-12 col-lg-5 mb-3">

                                <div className="card card__resultats h-100">
                                    <div className="card-body">

                                        <h5 className="card-title">100%</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">d'entre-vous satisfaits</h6>

                                    </div>
                                </div>

                            </div>
                            <div className="col-xs-12 col-lg-3 mb-3">

                                <div className="card card__devs h-100">
                                    <div className="card-body">

                                        <h5 className="card-title">Profitez de nos <br />développeurs les plus <br />talentueux.</h5>

                                        <div className="cta-section" onClick={() => this.creationRef.current.scrollIntoView({ behavior: 'smooth' })}>

                                            <div className="cta-icon-button"><i class="fi fi-sr-heart"></i></div>
                                            <span className="cta-label">Voir nos créations</span>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <div className="xl-separator" />
                <section className="contact" ref={this.contactRef}>

                    <div className="container">

                        <p>
                            Remplissez le formulaire ci-dessous pour nous contacter. Nous vous répondrons dans les plus brefs délais.<br />
                            Il s'agit d'un premier contact, et nous vous recontacterons pour discuter de votre projet en détail et ainsi vous faire une proposition adaptée à vos besoins.
                        </p>

                        <span className="form-category my-5">Coordonnées personnelles</span>

                        <div className="nx-form">
                            <label className="form-label">Identité</label>
                            <div className="form-pack">
                                <input type="text" className="form-input" placeholder="Jean Dupont" ref={this.requesterNameRef} defaultValue={this.state.nametag} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-md-6">

                                <div className="nx-form">
                                    <label className="form-label">Adresse e-mail</label>
                                    <div className="form-pack">
                                        <input type="text" className="form-input" placeholder="jean.dupont@naxalian.fr" ref={this.requesterMailRef} defaultValue={this.state.mail} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-6">
                                <div className="nx-form">
                                    <label className="form-label">Numéro de téléphone</label>
                                    <div className="form-pack">
                                        <input type="text" className="form-input" placeholder="(+33) 6 12 34 56 78" ref={this.requesterPhoneRef} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <span className="form-category my-5">Présentation de votre projet</span>

                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="nx-form">
                                    <label className="form-label">Nom du projet</label>
                                    <div className="form-pack">
                                        <input type="text" className="form-input" placeholder="Naxalian Beautiful Project" ref={this.nameRef} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="nx-form">
                                    <label className="form-label">Budget prévisionnel</label>
                                    <div className="form-pack">
                                        <input type="number" className="form-input" placeholder="Entrez le montant en EUROS (€)" ref={this.budgetRef} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="nx-form">
                            <label className="form-label">Description</label>
                            <div className="form-pack">
                                <textarea className="form-input" placeholder="Décrivez votre projet en quelques mots (précisez les liens vers des sites existants si vous en avez, vos réseaux, une maquette, etc.)" rows="5" ref={this.descriptionRef} />
                            </div>
                        </div>

                        <span className="form-category my-5">Prise d'informations</span>


                        <div className="nx-form">
                            <label className="form-label">Que voudriez-vous que nous fassions pour vous ?</label>
                            <div className="form-pack">
                                <textarea className="form-input" placeholder="Décrivez ce que vous auriez envie. Rentrez dans le détail si vous le pouvez. Si vous ne savez que globalement ce que vous voulez, écrivez brièvement votre idée, nous vous aiderons à la concevoir ensemble." rows="5" ref={this.orderDetailsRef} />
                            </div>
                        </div>

                        <div className="cta-section">

                            <p>
                                En cliquant sur le bouton ci-dessous, vous acceptez que vos données soient utilisées pour vous recontacter dans le cadre de votre demande. Nous ne les utilisons pas à des fins commerciales, et ne les partageons pas avec des tiers.<br />
                                Une fois la demande instruite, vous aurez accès à un espace personnel pour suivre l'avancement de votre projet si nous acceptons de le prendre en charge. <b>Veuillez rester joignable car nos développeurs vous contacteront pour discuter de votre projet avant de vous faire une proposition.</b>

                            </p>
                            <button className="cta-button text-decoration-none" onClick={(e) => {

                                if (e.target.disabled) return;
                                // Garder le bouton active jusqu'à la fin de l'animation
                                this.handleOrder();

                                e.target.disabled = true;
                                e.target.innerHTML = "Envoi en cours...";
                                e.target.style.cursor = "not-allowed";
                                e.target.style.opacity = "0.5";


                                setTimeout(() => {
                                    e.target.innerHTML = "Envoyer le formulaire <i class='fi fi-rr-paper-plane ms-2' />";
                                    e.target.style.cursor = "pointer";
                                    e.target.style.opacity = "1";

                                    e.target.style.animation = "press 1s";


                                    setTimeout(() => {
                                        e.target.style.animation = "none";
                                        e.target.disabled = false;
                                    }, 1000);

                                }, 500);



                            }}>Envoyer le formulaire <i class="fi fi-rr-paper-plane ms-2" /></button>
                        </div>



                    </div>



                </section>


                <section className="start" ref={this.startRef}>
                    <div className="container">
                        <h2 className="start-title">Vaincre la <span className="text-jungle">jungle</span><br />du numérique à travers<br />une conception avec <br />vous et à <span className="text-sea">votre service.</span></h2>
                        <div className="row">
                            <div className="col-xs-12 col-lg-8 col-xl-7 col-xxl-6">
                                <p>
                                    Le monde du web est un incontournable pour tout projet, que ce soit pour une entreprise, une association, un artiste, ou un particulier. Cependant, il est souvent difficile de s'y retrouver, et de savoir par où commencer. C'est là que <b>nous</b> intervenons.

                                    <br /><br />

                                    Votre projet a besoin de visibilité : nous vous y aidons. Naxalian Studios vous accompagne dans la création de votre site web et bien plus. C'est un <b>écosystème numérique complet</b> que nous vous proposons créé sur mesure et en collaboration avec vous pour représenter au mieux votre projet : <b>site publique, espace client, interface de gestion interne, etc</b>.
                                </p>
                                <p>
                                    Nous pensons et créons cet écosystème de votre projet en partant de A à Z tout en prenant en compte <b>vos besoins et vos envies</b>. <br />
                                    Du début à la fin, nos développeurs vous accompagnent et sont à votre écoute pour vous offrir <b>un produit qui vous ressemble</b>.
                                </p>
                            </div>
                        </div>
                    </div>

                </section>
                <div className="xl-separator" />
                <section className="projects">
                    <div className="container">

                        <h2 className="section-title" ref={this.creationRef}>Nos créations</h2>

                        <div className="row">

                            <div className="col-xs-12 col-lg-8">

                                <div className="card card__project">

                                    <img src={"https://www.naxalian.fr/images/workplace_screen.png"} alt="Project thumbnail" className="card-img-top" />

                                    <div className="hover-overlay">
                                        <div className="hover-overlay-content">
                                            <h6 className="card-title">Naxalian Workplace</h6>
                                            <span className="card-subtitle">PaaS de gestion de projets</span>

                                            <div className="cta-section">
                                                <div className="cta-icon-button" onClick={() => { toast("Merci !", { icon: "😍" }) }}><i class="fi fi-sr-heart"></i></div>
                                                <div className="cta-icon-button" onClick={() => { window.open("https://naxalian.fr"); }}><i class="fi fi-sr-link"></i></div>
                                                <div className="cta-icon-button" onClick={() => { window.open("https://www.naxalian.fr/images/workplace_screen.png", "_blank"); }}><i class="fi fi-rr-images"></i></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-lg-4">

                                <div className="card card__project">

                                    <img src={process.env.PUBLIC_URL + "/assets/lpomonod.png"} alt="Project thumbnail" className="card-img-top" />

                                    <div className="hover-overlay">
                                        <div className="hover-overlay-content">
                                            <h6 className="card-title">Lycée Gustave Monod</h6>
                                            <span className="card-subtitle">Design de site web</span>

                                            <div className="cta-section">
                                                <div className="cta-icon-button" onClick={() => { toast("Merci !", { icon: "😍" }) }}><i class="fi fi-sr-heart"></i></div>
                                                <div className="cta-icon-button" onClick={() => {
                                                    window.open(process.env.PUBLIC_URL + "/assets/lpomonod.png", "_blank");
                                                }}><i class="fi fi-rr-images"></i></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-xs-12 col-lg-4">

                                <div className="card card__project">

                                    <img src={process.env.PUBLIC_URL + "/assets/ssrm.png"} alt="Project thumbnail" className="card-img-top" />

                                    <div className="hover-overlay">
                                        <div className="hover-overlay-content">
                                            <h6 className="card-title">SSRM</h6>
                                            <span className="card-subtitle">Panneau de gestion d'événement sportif en direct</span>

                                            <div className="cta-section">
                                                <div className="cta-icon-button" onClick={() => { toast("Merci !", { icon: "😍" }) }}><i class="fi fi-sr-heart"></i></div>
                                                <div className="cta-icon-button" onClick={() => { window.open(process.env.PUBLIC_URL + "/assets/ssrm.png", "_blank"); }}><i class="fi fi-rr-images"></i></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-xs-12 col-lg-8">

                                <div className="card card__project">

                                    <img src={process.env.PUBLIC_URL + "/assets/eclipsia.png"} alt="Project thumbnail" className="card-img-top" />

                                    <div className="hover-overlay">
                                        <div className="hover-overlay-content">
                                            <h6 className="card-title">Eclipsia</h6>
                                            <span className="card-subtitle">Site d'un serveur Minecraft</span>

                                            <div className="cta-section">
                                                <div className="cta-icon-button" onClick={() => { toast("Merci !", { icon: "😍" }) }}><i class="fi fi-sr-heart"></i></div>
                                                <div className="cta-icon-button" onClick={() => { window.open(process.env.PUBLIC_URL + "/assets/eclipsia.png", "_blank"); }}><i class="fi fi-rr-images"></i></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                <Footer />

            </div>
        );
    }
}


const HomeExport = (props) => <Home {...props} location={useLocation()} />;
export default HomeExport;
