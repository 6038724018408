import React from 'react';
import '../../components/styles/dashboard.scss';
import Navigation from '../../components/Navigation';
import Loader from '../../components/Loader';
import { useLocation, useParams } from 'react-router-dom';
import { cancelOrder, confirmEstimate, downloadFile, getOrderinfo, userinfo } from '../../components/api';
import toast from 'react-hot-toast';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

class Tracking extends React.Component {

    constructor(props) {
        super(props)

        this.creationRef = React.createRef()
        this.startRef = React.createRef()
        this.contactRef = React.createRef()

        this.state = {
            loading: true,
            orderinfo: [],
            history: [],
            estimateinfo: [],
            invoiceinfo: [],
            files: [],
        }
    }

    componentDidMount() {

        userinfo().then((res) => {
            if (!res && !res.nametag) return toast.error("Une erreur est survenue lors de la récupération de vos informations.");
            this.setState({ nametag: res.displayname ? res.displayname : "@" + res.nametag });
        }).catch((error) => {
            toast.error("Vous devez être connecté pour accéder à cette page.");
            let base64 = btoa("https://studios.naxalian.fr/?token=")
            window.location.href = "https://naxalian.fr/login?redirect_uri=" + base64;
        });

        getOrderinfo(this.props.params.orderId).then((res) => {
            this.setState({ orderinfo: res.orderinfo, history: res.history, loading: false, estimateinfo: res.estimate, invoiceinfo: res.invoice, files: res.files });
            if (res.orderinfo.status === 0 || res.orderinfo.status === 6) return window.location.href = "./";
        }).catch((error) => {
            toast.error("Une erreur est survenue lors de la récupération des informations de la commande.");
        })
    }



    handleLoad = () => { this.setState({ loading: true }) }

    handleCancelOrder = () => {

        if (!window.confirm("Êtes-vous sûr de vouloir annuler cette commande ?")) return toast.success("Action annulée.");

        this.setState({ loading: true });

        cancelOrder(this.props.params.orderId).then((res) => {
            this.setState({ loading: false, orderinfo: { ...this.state.orderinfo, status: 3 } });
            toast.success(res);
        }).catch((error) => {
            this.setState({ loading: false });
            toast.error("Une erreur est survenue lors de l'annulation de la commande.");
        })

    }
    handleAccept = () => {

        if (!window.confirm("Êtes-vous sûr de vouloir accepter ce devis ?")) return toast.success("Action annulée.");

        this.setState({ loading: true });

        confirmEstimate(this.props.params.orderId).then((res) => {
            this.setState({ loading: false, orderinfo: { ...this.state.orderinfo, status: 7 } });
            toast.success(res);
        }).catch((error) => {
            this.setState({ loading: false });
            toast.error("Une erreur est survenue lors de l'annulation de la commande.");
        })

    }

    handleDownload = (url, filename) => {

        // to base64
        url = btoa(url);

        downloadFile(this.props.params.orderId, url).then((response) => {
            const blob = new Blob([response.blob], { type: response.contentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            console.log(this.props)
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);

        }).catch((error) => {
            toast.error("Une erreur est survenue lors du téléchargement du fichier.");
        })

    }

    render() {
        return (
            <div className="Dashboard">

                <Navigation darkNav={false} />
                {this.state.loading ? <Loader /> : null}

                <section className="hero">

                    <div className="container">

                        <h1 className="hero-title">{this.state.orderinfo.name ? this.state.orderinfo.name : "Non désigné"}</h1>
                        <span className="hero-subtitle">
                            Cet espace est dédié au suivi et au bon déroulement de vos projets et assure une communication transparente et efficace.<br />
                            Vous trouverez ici toutes les informations relatives à la commande <b>#{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}</b> dont son historique.
                        </span>

                    </div>


                </section>

                <section className="project-list mb-5">

                    <div className="container">

                        <div className="project d-none d-md-flex">

                            <div className="presentation">
                                <div className="identity">
                                    <span className="title">{this.state.orderinfo.name ? this.state.orderinfo.name : "Non désigné"}</span>
                                    <small className="text-muted">
                                        #{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}
                                    </small>
                                </div>
                            </div>

                            <div className="tracking">
                                <small className="text-muted">Fait le {this.state.orderinfo.create_date ? new Date(this.state.orderinfo.create_date * 1000).toLocaleDateString() : "UNKNOWN"}</small>
                                <span className={"status status__" +
                                    (this.state.orderinfo.status === 0 ? "pending" : (this.state.orderinfo.status === 1 ? "in-progress" : (this.state.orderinfo.status === 7 ? "action__required" : (this.state.orderinfo.status === 6 ? "none" : (this.state.orderinfo.status === 2 ? "finished" : (this.state.orderinfo.status === 3 ? "canceled" : (this.state.orderinfo.status === 4 ? "rejected" : (this.state.orderinfo.status === 5 ? "action__required" : "rejected"))))))))}>
                                    {this.state.orderinfo.status === 0 ? "En attente d'instruction" : (this.state.orderinfo.status === 7 ? "En attente de paiement" : (this.state.orderinfo.status === 6 ? "En cours d'instruction" : (this.state.orderinfo.status === 1 ? "En cours de réalisation" : (this.state.orderinfo.status === 2 ? "Commande terminée" : (this.state.orderinfo.status === 3 ? "Commande annulée" : (this.state.orderinfo.status === 4 ? "Commande rejetée" : (this.state.orderinfo.status === 5 ? "Acceptée, action requise" : "Commande perdue")))))))}
                                </span>
                            </div>

                            <div className="actions">
                                <button className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailModal" disabled={this.state.orderinfo.status === 3}>Détails <i class="fi fi-rr-info ms-2" /></button>
                                <button className="btn btn-link" onClick={() => {
                                    window.open("./" + this.state.orderinfo.id + "/invoice", "_blank");
                                }} disabled={!this.state.invoiceinfo || this.state.invoiceinfo.length === 0}>Facture <i class="fi fi-rr-calculator-bill ms-2" /></button>
                            </div>

                        </div>

                        <div className="project d-flex d-md-none">

                            <div className="presentation">
                                <div className="identity">
                                    <span className="title">{this.state.orderinfo.name ? this.state.orderinfo.name : "Non désigné"}</span>
                                    <small className="text-muted">
                                        #{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}
                                    </small>
                                </div>
                            </div>

                            <div className="tracking">
                                <span className={"status status__" +
                                    (this.state.orderinfo.status === 0 ? "pending" : (this.state.orderinfo.status === 1 ? "in-progress" : (this.state.orderinfo.status === 7 ? "action__required" : (this.state.orderinfo.status === 6 ? "none" : (this.state.orderinfo.status === 2 ? "finished" : (this.state.orderinfo.status === 3 ? "canceled" : (this.state.orderinfo.status === 4 ? "rejected" : (this.state.orderinfo.status === 5 ? "action__required" : "rejected"))))))))}>
                                    {this.state.orderinfo.status === 0 ? "En attente d'instruction" : (this.state.orderinfo.status === 7 ? "En attente de paiement" : (this.state.orderinfo.status === 6 ? "En cours d'instruction" : (this.state.orderinfo.status === 1 ? "En cours de réalisation" : (this.state.orderinfo.status === 2 ? "Commande terminée" : (this.state.orderinfo.status === 3 ? "Commande annulée" : (this.state.orderinfo.status === 4 ? "Commande rejetée" : (this.state.orderinfo.status === 5 ? "Acceptée, action requise" : "Commande perdue")))))))}
                                </span>
                            </div>

                        </div>

                        <div className="d-grid d-md-none">

                            <button className="btn btn-dark btn-sm my-1" data-bs-toggle="modal" data-bs-target="#detailModal" disabled={this.state.orderinfo.status === 3}>Détails <i class="fi fi-rr-info ms-2" /></button>
                            <button className="btn btn-dark btn-sm my-1" onClick={() => {
                                window.open("./" + this.state.orderinfo.id + "/invoice", "_blank");
                            }} disabled={!this.state.invoiceinfo || this.state.invoiceinfo.length === 0}>Facture <i class="fi fi-rr-calculator-bill ms-2" /></button>
                        </div>

                    </div>

                </section>
                <div class="modal modal-lg fade" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="detailModalLabel">Détail de la commande #{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <b>Budget initial: </b> {this.state.orderinfo.budget ? this.state.orderinfo.budget + "€" : "Non spécifié."}<br /><br />

                                <Markdown children={this.state.orderinfo.content ? this.state.orderinfo.content : "Aucun contenu spécifié."} remarkPlugins={[remarkGfm]} />

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-dark btn-sm" data-bs-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="tracking">

                    <div className="container">

                        <div className="row">

                            <div className="col-xs-12 col-md-8">

                                {this.state.orderinfo.status === 5 ? <>
                                    <div className="alert alert-danger">
                                        <i class="fi fi-rr-info me-2" /> Certaines actions sont requises pour continuer le processus de votre commande.
                                    </div>

                                    <div className="card mb-3">

                                        <div className="card-body">

                                            <h5 className="card-title">Actions requises</h5>

                                            <div className="required-actions">

                                                <p>
                                                    Nous avons traité votre commande et nous avons besoin de votre action pour continuer.<br />
                                                    Nos équipes sont à votre disposition pour vous aider à finaliser votre commande. Pour continuer le processus, veuillez suivre les instructions ci-dessous.

                                                    <ul>
                                                        <li>Assurez-vous que toutes les informations de votre commande sont correctes.</li>
                                                        <li>Assurez-vous que le devis est conforme à vos attentes.</li>
                                                    </ul>

                                                    Avant le début de la réalisation du projet, vous devrez valider le devis et régler 50% du montant total de la commande.<br />
                                                    Si le devis ne vous satisfait pas, vous pouvez contacter le support pour demander des modifications ou des précisions.

                                                    <button className="btn-bill"
                                                        data-bs-toggle="modal" data-bs-target="#estimate"
                                                    >Consulter le devis <i class="fi fi-rr-calculator-bill ms-2" /></button>

                                                    <div class="modal modal-lg fade" id="estimate" tabindex="-1" aria-labelledby="estimateLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h1 class="modal-title fs-5" id="estimateLabel">Estimation de la commande #{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}</h1>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">


                                                                    <Markdown children={this.state.estimateinfo.detail_md ? this.state.estimateinfo.detail_md : "Aucun contenu spécifié."} remarkPlugins={[remarkGfm]} />

                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-dark btn-sm" data-bs-dismiss="modal">Fermer</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <b className="title-placeholder">Actions</b>
                                                    <div className="cta">
                                                        <button className="btn btn-primary" onClick={this.handleAccept}>Valider le devis et commencer <i class="fi fi-sr-check ms-2" /></button>
                                                        <button className="btn btn-link" onClick={() => {
                                                            window.open("mailto:contact@naxalian.fr?subject=Commande " + this.state.orderinfo.id + " - Demande de précisions&body=Bonjour,\n\nCordialement,\n" + this.state.nametag);
                                                        }}>Contacter le support</button>
                                                        <button className="btn btn-link" onClick={this.handleCancelOrder}>Annuler la commande</button>
                                                    </div>


                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </> : this.state.orderinfo.status === 3 ? <>
                                    <div className="alert alert-danger">
                                        <i class="fi fi-rr-info me-2" /> Vous avez annulé cette commande. Aucun remboursement post-validation ne sera effectué.<br />S'il s'agit d'une erreur, veuillez <b>contacter sans délai</b> le support.
                                    </div>
                                </> : this.state.orderinfo.status === 7 ? <>
                                    <div className="alert alert-primary">
                                        <i class="fi fi-rr-info me-2" /> Vous avez accepté le devis. Nous attendons le premier versement pour commencer le projet.
                                    </div>
                                </> : this.state.orderinfo.status === 2 ? <>
                                    <div className="alert alert-success">
                                        <i class="fi fi-rr-check me-2" /> Votre commande est terminée. Nous vous remercions pour votre confiance.
                                    </div>
                                </> : null}


                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Historique</h5>

                                        <div className="history">
                                            {this.state.history && this.state.history.length > 0 ? this.state.history.map((event, index) => (
                                                <div className="event" key={index}>
                                                    <div className="infos">
                                                        <span className="date">{new Date(event.update_timestamp * 1000).toLocaleDateString()} {new Date(event.update_timestamp * 1000).toLocaleTimeString()}</span>
                                                        <span className="description">{event.title} #{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}</span>
                                                    </div>
                                                    <span className="content">{event.content}</span>
                                                </div>
                                            )) : <span className="text-muted">Il semblerait que la commande n'ait pas encore été commencée.</span>}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-4">

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Récapitulatif</h5>

                                        {(this.state.invoiceinfo && this.state.invoiceinfo.price) || (this.state.invoiceinfo && this.state.invoiceinfo.price === 0) ? <p className="card-text">Facturé : {this.state.invoiceinfo.price.toFixed(2) + "€"}</p> : this.state.estimateinfo && this.state.estimateinfo.price ? <p className="card-text">Prix proposé : {this.state.estimateinfo.price.toFixed(2) + "€"}</p> : null}
                                        {this.state.orderinfo && this.state.orderinfo.budget ? <p className="card-text">Budget initial : {parseFloat(this.state.orderinfo.budget).toFixed(2) + "€"} {
                                            ((this.state.invoiceinfo && this.state.invoiceinfo.price) || (this.state.invoiceinfo && this.state.invoiceinfo.price === 0)) ? <span className="text-muted">({Math.round(this.state.invoiceinfo.price / this.state.orderinfo.budget * 100)}%)</span> : null
                                        }</p> : null}
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Besoin d'aide ?</h5>
                                        <p className="card-text">Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter. Nous sommes là pour vous aider.</p>

                                        <i class="fi fi-rr-envelope" /> contact@naxalian.fr
                                    </div>
                                </div>
                                <div className="card mb-3">

                                    <div className="card-body">

                                        <h5 className="card-title">Documents associés</h5>


                                        {
                                            this.state.invoiceinfo ?
                                                <div className="document" onClick={() => {
                                                    window.open("./" + this.state.orderinfo.id + "/invoice", "_blank");
                                                }}>
                                                    <span className="icon"><i class="fi fi-rr-file-pdf" /></span>
                                                    <span className="description">Facture #{this.state.orderinfo.id ? this.state.orderinfo.id : "UNKNOWN"}</span>
                                                    <span className="date">{this.state.invoiceinfo.date_emitted ? new Date(this.state.invoiceinfo.date_emitted * 1000).toLocaleDateString() : "N/A"}</span>
                                                </div>
                                                : <span className="text-muted">Aucun document n'est associé à cette commande.</span>

                                        }

                                        {this.state.files && this.state.files.length > 0 ? this.state.files.map((file, index) => (
                                            <div className="document" key={index} onClick={() => {
                                                this.handleDownload("https://cdn.naxalian.fr/studios/" + this.state.orderinfo.id + "/" + file, file);
                                            }}>
                                                <span className="icon">{file.endsWith('.pdf') ? <i class="fi fi-rr-file-pdf" /> : <i class="fi fi-rr-document" />}</span>
                                                <span className="description">{file.split('.').slice(0, -1).join('.')}</span>
                                                {/*<span className="date">{new Date(this.state.invoiceinfo.date_emitted * 1000).toLocaleDateString()}</span>*/}
                                            </div>
                                        )) : null}


                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </section>

            </div>
        );
    }
}

const TrackingExport = (props) => <Tracking {...props} location={useLocation()} params={useParams()} />;
export default TrackingExport;
