import React from 'react';

import { Link, NavLink } from 'react-router-dom';

class Navigation extends React.Component {
    render() {
        return (
            <>
                <nav className={this.props.darkNav ? "navbar navbar-expand-lg bg-dark d-none d-lg-flex" : "navbar navbar-expand-lg bg-transparent d-none d-lg-flex"}>
                    <div className="container">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <button className={this.props.darkNav ? "cta-icon-button me-2 bg-white text-dark" : "cta-icon-button me-2"} data-bs-toggle="offcanvas" data-bs-target="#offcanvasBurgerMenu" aria-controls="offcanvasBurgerMenu"><i className="fi fi-rr-bars-sort"></i></button>
                            </li>
                            <li className="nav-item">
                                <NavLink className={this.props.darkNav ? "nav-link text-white" : "nav-link"} to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={this.props.darkNav ? "nav-link text-white" : "nav-link"} to="/jobs">Jobs</NavLink>
                            </li>
                            {/* <li><NavLink className={this.props.darkNav ? "nav-link text-white" : "nav-link"} to="/">Blog</NavLink></li> */}
                        </ul>

                        <NavLink className="navbar-brand mx-auto text-center" to="/"><img src={process.env.PUBLIC_URL + '/assets/naxalian.png'} alt="Naxalian Studios logo" className="navbar-logo" /></NavLink>

                        <div className="d-flex">
                            <Link className={this.props.darkNav ? "cta-button text-decoration-none bg-white text-dark" : "cta-button text-decoration-none"} to="https://naxalian.fr/discord" target="_blank">Discord</Link>
                            <NavLink className={this.props.darkNav ? "cta-icon-button bg-white text-dark text-decoration-none" : "cta-icon-button text-decoration-none"} to="/clientarea"><i class="fi fi-sr-user"></i></NavLink>
                        </div>
                    </div>
                </nav>

                <nav className={this.props.darkNav ? "navbar navbar-expand-lg bg-dark d-flex d-lg-none" : "navbar navbar-expand-lg bg-transparent d-flex d-lg-none"}>

                    <div className="container">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <button className={this.props.darkNav ? "cta-icon-button me-2 bg-white text-dark" : "cta-icon-button me-2"} data-bs-toggle="offcanvas" data-bs-target="#offcanvasBurgerMenu" aria-controls="offcanvasBurgerMenu"><i className="fi fi-rr-bars-sort"></i></button>
                            </li>
                        </ul>

                        <div className="d-flex">
                            <Link className={this.props.darkNav ? "cta-button text-decoration-none bg-white text-dark" : "cta-button text-decoration-none"} to="https://naxalian.fr/discord" target="_blank">Discord</Link>
                            <NavLink className={this.props.darkNav ? "cta-icon-button bg-white text-dark text-decoration-none" : "cta-icon-button text-decoration-none"} to="/clientarea"><i class="fi fi-sr-user"></i></NavLink>
                        </div>
                    </div>
                </nav>

                <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasBurgerMenu" aria-labelledby="offcanvasBurgerMenuLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasBurgerMenuLabel">Naxalian Studios</h5>
                        <button className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body text-center border-dark">

                        <NavLink className="nav-link" to="/" data-bs-dismiss="offcanvas">Home</NavLink><br />

                        <NavLink className="nav-link" to="/jobs" data-bs-dismiss="offcanvas">Jobs</NavLink><br />

                        {/*<NavLink className="nav-link" to="/" data-bs-dismiss="offcanvas">Blog</NavLink><br />*/}

                        <div className="d-grid">

                            <Link className="cta-button text-decoration-none mb-0" to="https://naxalian.fr/discord" target="_blank">Discord</Link><br />
                            <NavLink className="cta-button text-decoration-none mt-0" to="/clientarea">Espace client <i class="fi fi-sr-user"></i></NavLink>

                        </div>



                    </div>
                </div>

            </>

        );
    }
}

export default Navigation;