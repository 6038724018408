import React from 'react';
import '../components/styles/home.scss';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Loader from '../components/Loader';

class Legal extends React.Component {

    constructor(props) {
        super(props)

        this.creationRef = React.createRef()
        this.startRef = React.createRef()
        this.contactRef = React.createRef()

        this.state = {
            loading: false
        }
    }

    handleLoad = () => { this.setState({ loading: true }) }

    render() {
        return (
            <div className="Home">

                <Navigation darkNav={false} />
                {this.state.loading ? <Loader /> : null}

                <section className="hero">

                    <div className="container">

                        <h1 className="hero-title">Mentions légales</h1>
                        <span className="hero-subtitle">Il est important de connaître qui révolutionne vos projets. C'est pourquoi nous vous présentons nos mentions légales.</span>

                    </div>


                </section>

                <section className="start" ref={this.startRef}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <p>
                                    En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site www.naxalian.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi:<br /><br />

                                    Propriétaire: <b>M. Ewen RODRIGUES DE OLIVEIRA</b><br/>
                                    Le propriétaire est en charge du développement (webmestre) ainsi que de la publication sur www.naxalian.fr<br/>
                                    Adresse électronique: <b>ewen.rdo@naxalian.fr</b><br/><br/>

                                    Hébergeur: <b>Raphaël L. 84400 APT, FRANCE</b><br/>
                                    L'hébergeur ne peut en aucun cas être tenu responsable du contenu de ce site.<br/>
                                    Raphaël L. agit en qualité d'hébergeur.<br/><br/>

                                    Toute reproduction, même partielle, ne peut être faite sans l'avis explicite du propriétaire.<br/>
                                    Toute utilisation de ce site ou de l'une de ses composantes, notamment les textes, images, marques, logos, sons, vidéos, etc., est interdite sans l'autorisation expresse du propriétaire.<br/>
                                    Naxalian ne peut être tenu responsable du contenu posté par les utilisateurs eux-mêmes.

                                </p>
                            </div>
                        </div>
                    </div>

                </section>

                <Footer />

            </div>
        );
    }
}

export default Legal;